

import styled from 'styled-components';
export const Main = styled.div`
/* eslint-disable prettier/prettier */

    width: 100%;
    height: 100%;

    .cover{
    width: 45%;
    height: 100%;
    position: fixed;
}
.cover img{
    width: 100%;
    object-fit: cover;
}
`;