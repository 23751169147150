import React from "react";
import { HomeStyle } from "./style";
import coffee from "../../../assets/images/others/coffee.jpg";
import cows from "../../../assets/images/others/cows.jpg";
import { Link } from "react-router-dom";
import Header from "components/header";
import AboutUs from "components/aboutUs";
const Home = () => {
  return (
    <div>
      <Header />
      <HomeStyle>
        <div className="main-cls">
          <div className="section-one-home">
            <div className="section-left-side">
              <img src={coffee} alt="home icon" className="home-main-img" />
            </div>
            <div className="section-right-side">
              <p className="home-sec-heading1">
                The Freshest Produce In The Area
              </p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat,
              </p>
              <div className="link-more">
                <Link to="#" className="Link-readmore">
                  Read More →
                </Link>
              </div>
            </div>
          </div>
          <div className="section-one-home">
            <div className="section-left-side-2">
              <p className="home-sec-heading1">
                The Freshest Produce In The Area
              </p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat,
              </p>
              <div className="link-more">
                <Link to="#" className="Link-readmore">
                  Read More →
                </Link>
              </div>
            </div>
            <div className="section-right-side-2 crd-pic">
              <img src={cows} alt="home icon" className="home-main-img" />
            </div>
          </div>
        </div>
      </HomeStyle>
      <AboutUs/>
 
    </div>
  );
};

export default Home;
