import React from "react";
import { SignInStyle } from "./style";
import { useNavigate } from "react-router-dom";
const SignIn = () => {
  const navigate= useNavigate();
  const gotoHomePage = ()=>{
    navigate('/')
  }
  return (
    <SignInStyle>
      <div className="container">
      
        <div className="forms">
          <div className="form-content">
            <div className="login-form">
              <div className="title">Sign in </div>
              <form action="#">
                <div className="input-boxes">
                  <div className="input-box">
                    <label>Email address</label>
                    <br />
                    <input
                      type="email"
                      required=""
                    />
                  </div>
                  <div className="button-input-box">
                    <button onClick={gotoHomePage}>CONTINUE WITH EMAIL</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SignInStyle>
  );
};

export default SignIn;
