import React from "react";
import { Link } from "react-router-dom";
import { NavBarForBorder, NavBarTopStyle } from "./style";
const NavBarTop = () => {
  return (
    <NavBarForBorder>
      <div className="top-header-border">
        <div className="top-header">
          <NavBarTopStyle>
            <div className="navbar-contain">
              <ul className="nav-menu-last">
                <li className="top-right-nav">
                  <Link to="/signin" className="top-right-nav-links">
                    Sign in
                  </Link>
                </li>
                {/* <li className="top-right-nav">
                  <Link to="/register" className="top-right-nav-links">
                    Register
                  </Link>
                </li> */}
                <li className="top-right-nav">
                  <Link to="#" className="top-right-nav-links">
                    Subscribe
                  </Link>
                </li>
              </ul>
            </div>
          </NavBarTopStyle>
        </div>
      </div>
    </NavBarForBorder>
  );
};

export default NavBarTop;
