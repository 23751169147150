import styled from "styled-components";
export const SignInStyle = styled.div`
padding: 0px;
margin: 0px;
box-sizing: border-box;
.container{
    display: flex;
    width: 100%;
}
.cover{
    width: 45%;
    height: 100%;
    position: fixed;
}
.cover img{
    width: 100%;
    object-fit: cover;
}
.forms{
    width: 55%;
    margin-left:45%;
    padding: 20px;
    
}
.form-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.login-form{
    width: 70%;
}
.title{
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    text-transform: none;
    margin: 0 auto;
    color: #000;
}
.input-box{
margin-top: 30px;
}
.input-box label{  
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.input-box input{
margin-top: 10px;
width: 100%;
outline: 0;
padding: 12px 10px;
font-size: 16px;
}
.button-input-box button{
    margin-top: 20px;
    width: 100%;
color: white;
    height: 50px;
    background-color: #000;
    border-width: 2px;
    border-style: solid;
    border-color: #000;
    line-height: 1.5em;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}
@media screen and (max-width: 768px) {

.cover{
    display: none;
}
.forms{
    width: 100%;
    margin-left:0%;
    padding: 20px; 
}

}
`;