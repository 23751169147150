import React from "react";
import story1 from "../../../assets/images/others/story1.jpg";
import story3 from "../../../assets/images/others/story3.jpg";
import story4 from "../../../assets/images/others/story4.jpg";

import cows from "../../../assets/images/others/cows.jpg";
import { IndianGroceryStyle } from "./style";
import { Link } from "react-router-dom";

const IndianGrocery = () => {
  return (
    <div>
      {/* <NavBar/> */}
      <IndianGroceryStyle>
        <div className="main-cls">
          <div className="section-one-home">
            <div className="section-left-side">
              <img src={story1} alt="home icon" className="home-main-img" />
            </div>
            <div className="section-right-side">
              <p className="home-sec-heading1">
                A Field & A Pear Picking Business
              </p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat.
              </p>
            </div>
          </div>
          <div className="section-one-home">
            <div className="section-right-side">
              <p className="home-sec-heading1">
                Love Of Fresh & Organic Produce
              </p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat
              </p>
            </div>
            <div className="section-left-side">
              <img src={cows} alt="home icon" className="home-main-img" />
            </div>
          </div>
          {/* add */}

          <div className="section-one-home">
            <div className="section-left-side">
              <img src={story3} alt="home icon" className="home-main-img" />
            </div>
            <div className="section-right-side">
              <p className="home-sec-heading1">Inspiration in Nature</p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat
              </p>
            </div>
          </div>

          <div className="section-one-home">
            <div className="section-right-side">
              <p className="home-sec-heading1">A Place to Buy Fresh</p>
              <p className="home-sec-discription">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat
              </p>
            </div>
            <div className="section-left-side">
              <img src={story4} alt="home icon" className="home-main-img" />
            </div>
          </div>
        </div>
      </IndianGroceryStyle>
      {/* <Footer /> */}
    </div>
  );
};

export default IndianGrocery;
