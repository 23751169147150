import styled from "styled-components";
export const ContactFormStyle = styled.div`
  padding: 20px 0px;

  .container {
    padding: 40px 0px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    @media screen and (max-width: 1092px) {
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  .heading {
    font-size: 22px;
    line-height: 41px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: capitalize;
    margin-bottom: 30px;
  }

  .contact-text {
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
    text-transform: none;
    color: #898581;
    margin: 8px 0px;
  }

  .form-group-main {
    display: flex;
    gap: 15px;
    @media screen and (max-width: 1092px) {
      flex-wrap: wrap;
    }
  
  }

  .form-group label {
    color: #4d4238;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: capitalize;
    @media screen and (max-width: 576px) {
    min-width:100%;
    }
  }

  .form-group input {
    padding: 10px 17px;
    border: 1px solid #d9d9d9;
    outline: 0;
    color: #8f8f8f;
    font-size: 13px;
    font-weight: 400;
    margin-top: 22px;
    @media screen and (max-width: 576px) {
    min-width:100%;
    }
  }

  .form-group textarea {
    width: 100%;
    margin: 22px 0px;
    padding: 10px 17px;
    border: 1px solid #e9e9e9;
    outline: 0;
    resize: none;

    color: #7f8385;
  }

  .button {
    padding: 0px;
    background-color: #ffffff;
    color: #4d4328;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }

  .button:hover {
    color: #d6b89e;
  }
  @media screen and (max-width: 576px) {
  .form-group{
width: 100%;
  }
}


  @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .section-contact-us {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .section-contact-us {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .section-contact-us {
      width: 100%;
    }
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .section-contact-us {
      width: 100%;
    }
  }

`;
