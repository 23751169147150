import styled from "styled-components";
export const FooterStyle = styled.div`
  padding: 50px 0px;
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      gap: 30px;
    }
    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }
  .footer-logo {
    text-align: center;
  }
  .footer-logo img {
    width: 100px;
    margin-bottom: 10px;
  }
  .footer-logo p {
    margin: 0;
    font-size: 14px;
    color: #a9a9a9;
  }
  .foter-heading {
    margin-bottom: 15px;
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    font-style: normal;
    text-transform: capitalize;
    color: #4d4238;
   
  }

  .footer-links address {
    font-style: normal;
    color: #a9a9a9;
  }

  .icon-footer {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  .footer-icon {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgb(214, 184, 158);
    cursor: pointer;
  }
  .icon-fb {
    color: white;
    font-size: 14px;
  }
  .footer-icon:hover {
    background-color: #898581;
  }

    @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .footer-container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .footer-container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .footer-container {
      width: 100%;
    }
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .footer-container {
      width: 100%;
    }
  }
`;
