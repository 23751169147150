import LandingRoutes from '../../../containers/pages/index'
import Footer from 'components/footer'
import NavBar from 'components/navBar'
import NavBarTop from 'components/navBar-top'
import React from 'react'

const MainLayout = () => {
  return (
    <div> 
      <NavBar/>
<LandingRoutes/>
      <Footer/>
    </div>
  )
}

export default MainLayout
