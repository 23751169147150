import AppRoutes from "./containers";


function App() {
  return (
    <AppRoutes />
  );
}

export default App;
