import styled from "styled-components";

export const NavBarStyle = styled.div`

  .top-logo {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  .top-logo img {
    height: 90px;
  }
  .navbar-container {
    padding-top: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 25px;
  }
  .nav-menu {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 25px;
  }

  .nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #4d4238;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .nav-links:hover{
    color: #d6b89e;
  }
  .nav-links:focus{
    color: #d6b89e;
  }
  li {
    list-style: none;
  }
  .menu-icon {
    display: none;
    font-size: 25px;
    cursor: pointer;
    margin: 10px 20px;
  }
  @media screen and (max-width: 768px) {
    .top-logo {
      display: none;
    }
    .main-clas-nav {
      /* padding: 0px 20px; */
      display: flex;
      justify-content: space-between;
    }
    .navbar-container {
      border: none;
      flex-direction: column;
      display: none;
      width: 100%;
    }
    .navbar.active .navbar-container {
      display: flex;
    }
    .menu-icon {
      display: flex;
      justify-content: flex-end;
    }
    .nav-menu {
      justify-content: flex-start;
      flex-direction: column;
      gap: 15px;
    }
  }

  @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .top-header{
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .top-header{
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .top-header{
      width: 100%;
    }
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .top-header{
      width: 100%;
    }
  }
`;

