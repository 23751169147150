//  eslint-disable prettier/prettier 

import AuthRoutes from "../../../containers/auth/index";
import signinimg from "../../../assets/images/others/signinimg.jpg";

import { Main } from "./style"
const AuthLayout = () => {
    return (
        <Main>
            <div className="cover">
          <img className="backImg" src={signinimg} alt="" />
        </div>
           
            <AuthRoutes />
        </Main>
    )   
}

export default AuthLayout;