import styled from "styled-components";
export const IndianGroceryStyle = styled.div`

 .main-cls{
    border-bottom: 1px solid #e5e5e5; 
} 
.section-one-home{
    margin: 30px 0px;
    display: flex;
    gap: 25px;
    align-items: center;

    @media screen and (max-width: 1120px) {
      margin: 5px 0px;
      flex-wrap: wrap;
    }
    
}
.home-sec-heading1{
    font-size: 22px;
    line-height: 41px;
    margin: 0px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: capitalize;
}
.home-sec-discription{
    margin: 25px 0px;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    color: #a2a2a2;
}
.Link-readmore{
 
    color: #4d4238;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
}
.Link-readmore:hover{
color: #d6b89e;
}
.link-more{
    margin-top: 50px;
}
.section-left-side{
   width: 50%;
   @media screen and (max-width: 1120px) {
    width: 100%;
      
    }

}
.section-right-side{
    width: 50%;
    @media screen and (max-width: 1120px) {
    width: 100%;
      
    }

}
.home-main-img{
    width: 100%;
     @media screen and (max-width: 1120px) {
    width: 100%;
      
    }
    
}


  @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .main-cls{
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .main-cls{
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .main-cls{
      width: 100%;
    }
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .main-cls{
      width: 100%;
    }
  }

`;