import React, { useState, useRef } from "react";
import { NavBarStyle } from "./style";
import { Link, NavLink } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import NavBarTop from "../navBar-top/index"
import logo from "../../assets/images/svgs/logo.svg";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef();

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
<div>
  <NavBarTop/>

    <NavBarStyle>
     
      <div className="top-logo">
        <img src={logo} alt="LOGO" />
      </div>
      <div className="main-clas-nav">
        <div className="menu-icon" onClick={handleMenuToggle}>
          {isMenuOpen ? <FiX /> : <FiMenu />}
        </div>
      </div>
      <nav ref={navRef} className={`navbar ${isMenuOpen ? "active" : ""}`}>
        <div className="navbar-container">
          <ul className="nav-menu" ref={navRef}>
            <li className="nav-item">
              <NavLink
                to="/"
                exact
                className="nav-links"
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/indiangrocery"
                className="nav-links"
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                Indian grocery
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/homecook"
                className="nav-links"
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                Home cook
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact"
                className="nav-links"
                activeClassName="active-link"
                onClick={handleNavLinkClick}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </NavBarStyle>
    </div>
  );
};

export default NavBar;
