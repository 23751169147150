import React from 'react'
import { AboutStyle } from './style'
import organic1 from "../../assets/images/others/organic1.jpg";
import organic2 from "../../assets/images/others/organic2.jpg";
import organic3 from "../../assets/images/others/organic3.jpg";

const AboutUs = () => {
  return (
    <AboutStyle>
      <div className='about-section'>
      <div>
        <p className='top-heading'>About Us</p>
        <p className='top-description'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros.</p>
      </div>
      <div className='images-section'>
        <div className='about-us-card-image'><img src={organic1}/></div>
        <div className='about-us-card-image'><img src={organic2}/></div>
        <div className='about-us-card-image'><img src={organic3}/></div>
      </div>
      </div>
    </AboutStyle>
  )
}

export default AboutUs
