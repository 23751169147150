import React from "react";  
import { ContactFormStyle } from "./style";

const ContactForm = () => {
  return (
    <div className="section-contact-us">
      <ContactFormStyle>
        <div className="container">
          <div className="contact-info">
            <p className="heading">Contact</p>
            <p className="contact-text">12345 Organic Road</p>
            <p className="contact-text">Organic Hill, SoHo, NY</p>
            <p className="contact-text">+1234567890</p>
            <p className="contact-text">+1234567890</p>
            <p className="contact-text">bodega4@example.com</p>
          </div>
          <div className="contact-form">
            <form>
              <div className="form-group-main">
                <div className="form-group">
                  <label htmlFor="firstName">First Name*</label>
                  <br />
                  <input type="text" id="firstName" required />
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">Last Name*</label>
                  <br />
                  <input type="text" id="lastName" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">E-Mail*</label>
                  <br />
                  <input type="email" id="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <br />
                  <input type="text" id="subject" />
                </div>
              </div>

              <div className="form-group">
                <textarea id="message" rows="10"></textarea>
              </div>
              <div className="button-container">
                <button type="submit" className="button">
                  CONTACT US →
                </button>
              </div>
            </form>
          </div>
        </div>
      </ContactFormStyle>
    </div>
  );
};

export default ContactForm;
