import styled from "styled-components";
export const AboutStyle = styled.div`

.about-section{
    padding:30px 0px;
    border-bottom: 1px solid #e5e5e5;
}
.top-heading{
    text-align: center;
    font-size: 26px;
    line-height: 41px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: capitalize;
}
.top-description{
    margin:30px 0px;
    text-align: center; 
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
    text-transform: none;
    color: #898581;
}
.images-section{

    display: flex;
    justify-content: center;
    gap: 20px;
    @media screen and (max-width: 768px) {
  
      display: flex;
     flex-wrap: wrap;
    }
    
}
.about-us-card-image{

    @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
}
.about-us-card-image img{
    width: 100%;
    @media screen and (max-width: 768px) {
        width: 100%;

}
}

    @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .about-section {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .about-section {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .about-section {
      width: 100%;
    }
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .about-section {
      width: 100%;
    }
  }

`;