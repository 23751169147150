import styled from "styled-components";
export const HomeCookStyle = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
  @media only screen and (min-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .section-home-cook {
      width: 100%;
    }
  }
  @media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .section-home-cook {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .section-home-cook {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .section-home-cook {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .section-home-cook {
      width: 100%;
    }
  }
`;

export const CookSectionOne = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
  @media only screen and (max-width: 768px) {
    padding: 0px 0px;
  }
  .top-heading {
    text-align: center;
    font-size: 26px;
    line-height: 41px;
    font-weight: 400;
    letter-spacing: 1px solid black;
    text-transform: capitalize;
  }
  .top-description {
    margin: 30px 0px;
    text-align: center;
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
    text-transform: none;
    color: #898581;
  }
  .icons-section {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 992px) {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: flex-start;
      justify-content: center;
    }
  }
  .icon-main-cls img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }
  .icon-name {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 41px;
    font-weight: 400;
    text-transform: capitalize;
  }
`;

export const CookSectionTwo = styled.div`
  .cook-section-2 {
    padding: 30px 0px;
    border-bottom: 1px solid #e5e5e5;
  }
  .top-heading {
    text-align: center;
    font-size: 26px;
    line-height: 41px;
    font-weight: 400;
    letter-spacing: 1px solid black;
    text-transform: capitalize;
  }
  .top-description {
    margin: 30px 0px;
    text-align: center;
    font-size: 13px;
    line-height: 27px;
    font-weight: 400;
    text-transform: none;
    color: #898581;
  }
  .images-section {
    display: flex;
    justify-content: center;
    gap: 15px;

    @media screen and (max-width: 1170px) {
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
  .section-images img {
    width: 269px;
    height: 202px;
  }
`;

export const CookSectionThree = styled.div`
  padding: 30px 0px;
  .cards-main {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
      justify-content: center;
    gap: 15px;

    }
  }

  .cards-main .box {
    width: calc(25% - 10px);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }

  .cards-main .box .image {
    margin: 16px 0;
    height: 110px;
    width: 110px;
    background: #e1cbb9;
    padding: 3px;
    border-radius: 50%;
  }
  .box .image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
  }

  .box .name_job {
    margin: 0px 0 3px 0;
    color: #e1cbb9;
    font-size: 18px;
    font-weight: 600;
  }

  ul {
    list-style-type: none;
  }

  @media (max-width: 1000px) {
    .detail-profile {
      display: flex;
      justify-content: center;
    }
    .detail-profile-sec {
      width: 80%;
    }
  }
  .detail-profile {
    width: 100%;
  }

  .detail-profile-sec {
    margin: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    gap: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .detail-profile-sec li p {
    text-align: center;
    font-size: 14px;
    color: #a9a9a9;
  }
  .detail-profile-sec li p:nth-child(2) {
    color: #e1cbb9;
    font-weight: bold;
    font-size: 16px;
  }
  .bio-data p{
    text-align: center;
    margin: 12px 0px;
    color: #a9a9a9;
    font-size: 14px;
  }
  .location-detail {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .location-detail span:first-child {
    color: #e1cbb9;
    font-weight: bold;
    padding-top: 1px;
  }
  .location-detail span:nth-child(2) {
    font-size: 14px;
    color: #a9a9a9;
  }
  .button-container {
    margin: 16px 0px;
  }

  .button-container .button {
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 700;
    font-style: normal;
    text-transform: capitalize;
    color: #4d4238;
    background-color: #e1cbb9;
    border-width: 0px;
    padding: 12px 14px;
    cursor: pointer;
  }
  .button-container .button:hover {
    color: #d6b89e;
    background-color: white;
  }

  @media (max-width: 1120px) {
    .cards-main .box {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
  @media (max-width: 600px) {
    .cards-main .box {
      width: 100%;
    }
  }
`;
