import styled from "styled-components";
export const HeaderStyle = styled.div`



padding-top: 35px;
width: 100%;
.header-image img{
display: flex;
justify-content: center;
width: 100%;
}


@media only screen and (min-width: 1200px) {
    width: 70%;
    margin: 0 auto;
    .header-image img{
width: 100%;
}
  }
  @media only screen and (max-width: 1280px) {
    width: 80%;
    margin: 0 auto;
    .header-image img{
width: 100%;
}
  }
  @media only screen and (max-width: 992px) {
    width: 85%;
    margin: 0 auto;
    .header-image img{
width: 100%;
}
  }
    @media only screen and (max-width: 576px) {
    width: 90%;
    margin: 0 auto;
    .header-image img{
width: 100%;
}
  }

`;