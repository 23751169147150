import Home from "./home";
import { Route, Routes } from "react-router-dom";
import HomeCook from "./homeCook";
import IndianGrocery from "./indianGrocery";
import ContactForm from "./contact";

const LandingRoutes = () => {
    return (
        <>
        <Routes>
            <Route path="/" Component={Home}/>
            <Route path="/homecook" Component={HomeCook}/>
            <Route path="/indiangrocery" Component={IndianGrocery}/>
            <Route path="/contact" Component={ContactForm}/>
        </Routes>
        </>
    )
}

export default LandingRoutes;