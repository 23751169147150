import React, { useEffect, useState } from "react";
import {
  HomeCookStyle,
  CookSectionOne,
  CookSectionTwo,
  CookSectionThree,
} from "./style";
import { useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";

import organic1 from "../../../assets/images/others/organic1.jpg";
import organic2 from "../../../assets/images/others/organic2.jpg";
import organic3 from "../../../assets/images/others/organic3.jpg";
import organic4 from "../../../assets/images/others/organic4.jpg";

import image1 from "../../../assets/images/svgs/img1.svg";
import image2 from "../../../assets/images/svgs/img2.svg";
import image3 from "../../../assets/images/svgs/img3.svg";
import image4 from "../../../assets/images/svgs/img4.svg";
import image5 from "../../../assets/images/svgs/img5.svg";
import image6 from "../../../assets/images/svgs/img6.svg";


import profilepic from "../../../assets/images/others/profilepic.jpg";
import useHook from "./useHook";

const HomeCook = () => {
  const [dietary, setDietary] = useState([]);
  const [getChefs, setGetChefs] = useState([]);

  const navigate = useNavigate();
  const goToContactUs = () => {
    navigate("/contact");
  };

  // const { getDietyData, getCheifDetail } = useHook();
  // const Dietry = async () => {
  //   const params = {
  //     page: 1,
  //     limit: 6,
  //   };
  //   const response = await getDietyData(params);
  //   setDietary(response.data);
  //   console.log(response.data);
  //   console.log("helo", dietary);
  // };

  // const Chefs = async () => {
  //   const params = {
  //     page: 1,
  //     limit: 4,
  //   };
  //   const response = await getCheifDetail(params);
  //   setGetChefs(response.data.results);
  //   console.log(response.data.results);
  //   console.log("helo chefs", getChefs);
  // };

  // useEffect(() => {
  //   Dietry();
  // }, []);

  // useEffect(() => {
  //   Chefs();
  // }, []);


  const dummyDietary = [
    {
      id: 1,
      dietaryImges : image1,
      title: "Beverages"
      
    },
    {
      id: 2,
      dietaryImges : image2,
      title: "Dairy"      
    },
    {
      id: 3,
      dietaryImges : image3,
      title: "Flour & Grains"
    },

    {
      id: 4,
      dietaryImges : image4,
      title: "Lentils"
    },
    {
      id: 5,
      dietaryImges : image5,
      title: "Produce"
    },
    {
      id: 6,
      dietaryImges : image6,
      title: "Spices"
    },
  ]

  const dummyChefs = [
    {
      id: 1,
      name: "John Doe",
      profilepic: profilepic,
      recipeCount: 25,
      followers: 150,
      rating: 4.5,
      bio: "A chef is a skilled culinary artist who transforms ingredients into delicious and visually appealing dishes.",
      location: "12345 Organic Road Organic Hill, SoHo, NY",
    },
    {
      id: 2,
      name: "Jane Smith",
      profilepic: profilepic,
      recipeCount: 35,
      followers: 200,
      rating: 4.8,
      bio: "With a passion for organic ingredients, Jane crafts delightful dishes with a focus on health and flavor.",
      location: "6789 Fresh Street, Culinary Valley, LA",
    },
    {
      id: 3,
      name: "Michael Brown",
      profilepic: profilepic,
      recipeCount: 40,
      followers: 300,
      rating: 4.9,
      bio: "A creative chef known for fusion cuisine, combining flavors from around the world into unforgettable meals.",
      location: "9876 Flavor Avenue, Gourmet Town, TX",
    },
    {
      id: 4,
      name: "Emily Johnson",
      profilepic: profilepic,
      recipeCount: 30,
      followers: 180,
      rating: 4.7,
      bio: "Emily specializes in farm-to-table cuisine, creating dishes that highlight fresh, local ingredients.",
      location: "4567 Green Lane, Farmville, CA"
    },
  ];

  return (
    <div>
      {/* <NavBar /> */}
      <HomeCookStyle>
        <div className="section-home-cook">
          <CookSectionOne>
            <div>
              <p className="top-heading">The Freshest Produce in The Area</p>
              <p className="top-description">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat.
              </p>
              <div className="icons-section">
                {dummyDietary.map((item) => (
                <div>
                  <div className="icon-main-cls">
                    <img src={item.dietaryImges} alt="Prcess1" />
                  </div>
                  <p className="icon-name">{item.title}</p>
                </div>
              ))}
              </div>
            </div>
          </CookSectionOne>
          <CookSectionTwo>
            <div className="cook-section-2">
              <div>
                <p className="top-heading">The Freshest Produce in The Area</p>
                <p className="top-description">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                  aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                  nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                  aliquip ex ea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velit esse molestie consequat,
                  vel illum dolore eu feugiat nulla facilisis at vero eros.
                </p>
              </div>
              <div className="images-section">
                <div className="section-images">
                  <img src={organic1} />
                </div>
                <div className="section-images">
                  <img src={organic2} />
                </div>
                <div className="section-images">
                  <img src={organic3} />
                </div>
                <div className="section-images">
                  <img src={organic4} />
                </div>
              </div>
            </div>
          </CookSectionTwo>
          <CookSectionThree>
            {
              <div className="cards-main">
                {/* {dummyChefs.map((item, index) => (
                <div className="box">
                  <div className="image">
                    <img src={profilepic}  />
                  </div>
                  <div className="name_job">hamza</div>
                  <div className="detail-profile">
                    <ul className="detail-profile-sec">
                      <li>
                        <p>Recipe</p>
                        <p>25</p>
                      </li>
                      <li>
                        <p>Followers</p>
                        <p>150</p>
                      </li>

                      <li>
                        <p>Rating</p>
                        <p>4.5</p>
                      </li>
                    </ul>
                  </div>
                  <div className="bio-data">
                    <p>
                      A chef is a skilled culinary artist who transforms
                      ingredients into delicious and visually appealing dishes.
                    </p>
                  </div>

                  <div className="location-detail">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span>12345 Organic Road Organic Hill, SoHo, NY</span>
                  </div>
                  <div className="button-container">
                    <button className="button" onClick={goToContactUs}>
                      Read More →
                    </button>
                  </div>
                </div>
              ))}  */}

                <div className="cards-main">
                  {dummyChefs.map((item, index) => (
                    <div className="box" key={index}>
                      <div className="image">
                        <img
                          src={item.profilepic}
                          alt={`${item.name}'s profile`}
                        />
                      </div>
                      <div className="name_job">{item.name}</div>
                      <div className="detail-profile">
                        <ul className="detail-profile-sec">
                          <li>
                            <p>Recipe</p>
                            <p>{item.recipeCount}</p>
                          </li>
                          <li>
                            <p>Followers</p>
                            <p>{item.followers}</p>
                          </li>
                          <li>
                            <p>Rating</p>
                            <p>{item.rating}</p>
                          </li>
                        </ul>
                      </div>
                      <div className="bio-data">
                        <p>{item.bio}</p>
                      </div>
                      <div className="location-detail">
                        <span>
                          <FaLocationDot />
                        </span>
                        <span>{item.location}</span>
                      </div>
                      <div className="button-container">
                        <button className="button" onClick={goToContactUs}>
                          Read More →
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
          </CookSectionThree>
        </div>
      </HomeCookStyle>

      {/* <Footer /> */}
    </div>
  );
};

export default HomeCook;
