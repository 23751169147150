import React from 'react'
import headerimg from "../../assets/images/headerimg.png"
import { HeaderStyle } from './style'
const Header = () => {
  return (
    <HeaderStyle>
        <div className='header-image'>
        <img src={headerimg} alt='HEADER IMAGE'/>
        </div>
    
    </HeaderStyle>
  )
}

export default Header
