import logo from "../../assets/images/svgs/logo.svg";
import { FooterStyle } from "./style";
import React from "react";
import { FaFacebookF, FaTwitter, FaVimeoV, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logo} alt="Logo" />
          <div className="icon-footer">
            <span className="footer-icon">
              <FaFacebookF className="icon-fb" />
            </span>
            <span className="footer-icon">
              <FaTwitter className="icon-fb" />
            </span>
            <span className="footer-icon">
              <FaVimeoV className="icon-fb" />
            </span>
            <span className="footer-icon">
              <FaInstagram className="icon-fb" />
            </span>
          </div>
        </div>
        <div className="footer-links " >
          <p className="foter-heading">Where To Find Us</p>
          <address>
            253 Lake Street
            <br />
            Lake Hill
            <br />
            NY, NY
          </address>
        </div>
        <div className="footer-links" style={{marginTop:"25px"}}>
          <address>
            253 Lake Street
            <br />
            Lake Hill
            <br />
            NY, NY
          </address>
        </div>
        <div className="footer-links" style={{marginTop:"25px"}}>
          <address>
            253 Lake Street
            <br />
            Lake Hill
            <br />
            NY, NY
          </address>
        </div>
     
      </div>
    </FooterStyle>
  );
};

export default Footer;
